import React from "react";

const Home = () => {
  return (
    <div className="home">
      <h2 className="disclaimer">This page is moved to{" "}<a href="https://cadenmf.com/api" target="_blank" rel="noopener noreferrer">cadenmf.com/api</a>. You will NOT need to change the links on your bookmarks. <a href="/" target="_self">api.cadenmf.com</a> will stay as the API endpoint.</h2>
    </div>
  );
};

export default Home;
